/* Centered Card Layout with Equal Margins */

/* Card Wrapper Container - with centered content */
.card-wrapper {
  width: 90% !important; /* Reduced from 100% to 90% */
  max-width: 1400px !important; /* Max width to prevent stretching on large screens */
  margin-left: auto !important;
  margin-right: auto !important;
  animation: slideInFromLeft 1s ease-out;
  padding-top: 40px !important;
  padding-bottom: 70px !important;
  box-sizing: border-box !important;
}

/* Section Header */
.card-wrapper h2 {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.75rem !important;
  color: #111827 !important;
  margin-bottom: 2rem !important;
  letter-spacing: -0.02em !important;
  position: relative !important;
  display: inline-block !important;
  padding-left: 1.25rem !important;
  text-transform: none !important;
}

/* Vertical accent for section header */
.card-wrapper h2::before {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 5px !important;
  height: 28px !important;
  background: linear-gradient(to bottom, #004400, #00aa00) !important;
  border-radius: 8px !important;
}

/* Card Container - centered with equal margins */
.card-container {
  display: flex !important;
  flex-wrap: wrap !important;
  width: 100% !important;
  box-sizing: border-box !important;
  justify-content: center !important; /* Center cards */
}

/* Column Styling - Fixed width with margins */
.col-lg-3.col-md-3.col-12 {
  padding: 0.75rem !important;
  transition: transform 0.3s ease !important;
  box-sizing: border-box !important;
  /* No fixed width to allow centering */
}

/* Modern Card Design */
.image-cards {
  cursor: pointer !important;
  border: none !important;
  border-radius: 16px !important;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  height: 230px !important;
  overflow: hidden !important;
  position: relative !important;
  background: white !important;
  box-shadow:
          0 10px 30px -15px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  width: 100% !important;
  text-transform: uppercase !important;
  display: block !important;
}

/* Hover Effects - No Bounce */
.image-cards:hover {
  box-shadow:
          0 20px 40px -20px rgba(0, 0, 0, 0.2),
          0 8px 16px -4px rgba(0, 0, 0, 0.1),
          0 0 0 1px rgba(0, 80, 0, 0.05) !important;
  transform: translateY(-8px) !important;
}

/* Card Image Styling */
.custom-card-img {
  width: 85px !important;
  height: 85px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 30px !important;
  margin-bottom: 25px !important;
  display: block !important;
  object-fit: contain !important;
  animation: fadeIn 1.5s ease-in-out 1 !important;
  transition: transform 0.5s ease !important;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1)) !important;
}

/* Card Image Hover Effect */
.image-cards:hover .custom-card-img {
  transform: scale(1.15) !important;
  filter: drop-shadow(0 6px 10px rgba(0, 80, 0, 0.3)) !important;
}

/* Card Body Styling */
.custom-card-body {
  text-align: center !important;
  background-color: #E8F5E8 !important;
  padding: 1.25rem !important;
  border-top: 1px solid rgba(0, 80, 0, 0.08) !important;
  transition: all 0.3s ease !important;
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
}

/* Card Title */
.card-title {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  letter-spacing: 0.02em !important;
  margin-bottom: 0 !important;
  position: relative !important;
  z-index: 2 !important;
  transition: transform 0.3s ease !important;
}

/* Enhanced Card Body Hover Effect */
.image-cards:hover .custom-card-body {
  background: linear-gradient(150deg, #004400, #008000) !important;
  color: white !important;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1) !important;
}

/* Card Glow Effect */
.image-cards::after {
  content: "" !important;
  position: absolute !important;
  top: -50% !important;
  left: -50% !important;
  width: 200% !important;
  height: 200% !important;
  background: radial-gradient(
                  circle at center,
                  rgba(0, 170, 0, 0.05) 0%,
                  rgba(0, 0, 0, 0) 70%
  ) !important;
  opacity: 0 !important;
  transform: scale(0.5) !important;
  transition: opacity 0.6s ease, transform 0.6s ease !important;
  pointer-events: none !important;
  z-index: 1 !important;
}

.image-cards:hover::after {
  opacity: 1 !important;
  transform: scale(1) !important;
}

/* Fade In Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Slide In Animation */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Card Wrapper Enhancements */
.card-wrapper > h2 {
  animation: fadeIn 1s ease-in-out !important;
}

/* Make sure link takes full width */
.col-lg-3.col-md-3.col-12 a {
  width: 100% !important;
  display: block !important;
  text-decoration: none !important;
}

/* Dark Mode Support */
//@media (prefers-color-scheme: dark) {
//  .card-wrapper h2 {
//    color: #f1f5f9 !important;
//  }
//
//  .image-cards {
//    background: rgba(0, 30, 0, 0.7) !important;
//    box-shadow:
//            0 10px 30px -15px rgba(0, 0, 0, 0.4),
//            0 4px 6px -2px rgba(0, 0, 0, 0.3) !important;
//  }
//
//  .image-cards:hover {
//    box-shadow:
//            0 20px 40px -20px rgba(0, 0, 0, 0.5),
//            0 8px 16px -4px rgba(0, 0, 0, 0.3),
//            0 0 0 1px rgba(0, 100, 0, 0.2) !important;
//  }
//
//  .custom-card-body {
//    background-color: rgba(20, 40, 20, 0.8) !important;
//    color: #e2e8f0 !important;
//    border-top: 1px solid rgba(0, 100, 0, 0.2) !important;
//  }
//
//  .image-cards:hover .custom-card-body {
//    background: linear-gradient(150deg, #003300, #006600) !important;
//  }
//
//  .custom-card-img {
//    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3)) brightness(0.95) !important;
//  }
//
//  .image-cards:hover .custom-card-img {
//    filter: drop-shadow(0 6px 10px rgba(0, 100, 0, 0.5)) brightness(1.1) !important;
//  }
//}

/* Fixed Responsive Adjustments */
@media (max-width: 991.98px) {
  .card-wrapper {
    width: 95% !important;
    padding-top: 30px !important;
    padding-bottom: 50px !important;
  }

  .image-cards {
    height: 210px !important;
  }

  .custom-card-img {
    width: 70px !important;
    height: 70px !important;
    margin-top: 25px !important;
  }
}

@media (max-width: 767.98px) {
  .card-wrapper h2 {
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .card-wrapper h2::before {
    height: 24px !important;
    width: 4px !important;
  }

  .col-lg-3.col-md-3.col-12 {
    padding: 0.5rem !important;
  }

  .card-wrapper {
    width: 90% !important;
  }
}
