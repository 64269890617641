
//Fonts
@import "custom/fonts/fonts";
@import "custom/plugins/table";
@import "custom/plugins/breadcrumb";
@import "custom/plugins/search";
@import "custom/plugins/dashboard";
@import "custom/plugins/login";


//Core files
@import "variables";


@import "theme-light";
@import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/calendar-full";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/jobs";

@import 'react-dual-listbox/lib/react-dual-listbox.css';


.select2-dropdown {
  position: absolute;
  z-index: 9999;
}

.modal-header {
  background-color: #006400;
  color: #fff;
  margin-right: -0.1%;
  margin-top: -0.1%;
  margin-left: -0.1%;
}

.custom-modal {
  .modal-content {
    height: 500px; /* Set the desired height here */
  }
}

.custom-dual-list-box {
  height: 600px; /* Adjust this value as needed */
}

.custom-dropdown-menu {
  max-height: 9999px; /* Set the desired max height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
}

.form-details-header{
  color: #000;
  text-transform: uppercase;
  font-weight: bolder;
}
.submit-btn-helper {
  background-color: #008000 !important; /* SUA Green */
  color: white !important;
  font-size: inherit !important; /* Keeps the default text size */
  font-weight: normal !important;
  padding: 10px 20px; /* Adjusted padding for width */
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease-in-out;
  text-align: center;
}

.submit-btn-helper:hover,
.submit-btn-helper:disabled:focus {
  background-color: #006400 !important; /* Darker Green */
  transform: scale(1.05);
}

.is-invalid {
  border-color: red !important;
}

/* Keep green color while submitting */
.submit-btn-helper:disabled {
  background-color: #006400 !important; /* Darker Green */
  cursor: not-allowed;
}

