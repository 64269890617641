/* Professional Dashboard & UI Styling */

/* ======= SEARCH FORM STYLING ======= */
.app-search.d-none.d-lg-block {
  position: relative !important;
  margin-right: 1.75rem !important;
  transition: transform 0.3s ease !important;
}

.app-search:hover {
  transform: translateY(-1px) !important;
}

.app-search .position-relative {
  position: relative !important;
  border-radius: 12px !important;
  overflow: hidden !important;
}

.app-search .position-relative::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 10% !important;
  right: 10% !important;
  height: 1px !important;
  background: linear-gradient(90deg,
          rgba(0, 85, 0, 0) 0%,
          rgba(0, 85, 0, 0.2) 50%,
          rgba(0, 85, 0, 0) 100%) !important;
  opacity: 0 !important;
  transition: opacity 0.3s ease !important;
}

.app-search:hover .position-relative::after {
  opacity: 1 !important;
}

.app-search .position-relative::before {
  content: "\f002" !important; /* Font Awesome search icon */
  font-family: "Font Awesome 5 Free", "FontAwesome" !important;
  font-weight: 900 !important;
  position: absolute !important;
  left: 1.125rem !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  font-size: 0.875rem !important;
  color: #006600 !important;
  opacity: 0.85 !important;
  z-index: 3 !important;
  pointer-events: none !important;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1) !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
}

.app-search .form-control {
  height: 42px !important;
  padding-left: 3rem !important;
  padding-right: 1.25rem !important;
  background: rgba(255, 255, 255, 0.95) !important;
  border: 1px solid rgba(229, 231, 235, 0.9) !important;
  box-shadow:
          0 4px 12px rgba(0, 0, 0, 0.03),
          0 2px 3px rgba(0, 0, 0, 0.02),
          inset 0 1px 0 rgba(255, 255, 255, 0.7) !important;
  border-radius: 12px !important;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1) !important;
  font-size: 0.9375rem !important;
  font-weight: 500 !important;
  letter-spacing: -0.01em !important;
  color: #111827 !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
}

.app-search .form-control::placeholder {
  color: #6B7280 !important;
  opacity: 0.8 !important;
  font-weight: 400 !important;
  transition: all 0.2s ease !important;
}

.app-search .form-control:hover {
  border-color: rgba(0, 85, 0, 0.3) !important;
  box-shadow:
          0 5px 15px rgba(0, 0, 0, 0.03),
          0 3px 5px rgba(0, 0, 0, 0.02),
          inset 0 1px 0 rgba(255, 255, 255, 0.7) !important;
}

.app-search .form-control:hover::placeholder {
  opacity: 1 !important;
  color: #4B5563 !important;
}

.app-search .form-control:focus {
  background: #ffffff !important;
  border-color: rgba(0, 102, 0, 0.5) !important;
  box-shadow:
          0 0 0 3px rgba(0, 102, 0, 0.18),
          0 2px 4px rgba(0, 0, 0, 0.05) !important;
  outline: none !important;
}

.app-search .form-control:focus::placeholder {
  opacity: 0.6 !important;
}

.app-search .form-control:focus + .position-relative::before,
.app-search:hover .position-relative::before {
  opacity: 1 !important;
  color: #008800 !important;
  transform: translateY(-50%) scale(1.05) !important;
}

/* Search pulse animation */
@keyframes searchPulse {
  0% {
    box-shadow:
            0 4px 12px rgba(0, 0, 0, 0.03),
            0 2px 3px rgba(0, 0, 0, 0.02),
            inset 0 1px 0 rgba(255, 255, 255, 0.7) !important;
  }
  50% {
    box-shadow:
            0 6px 16px rgba(0, 0, 0, 0.04),
            0 3px 5px rgba(0, 0, 0, 0.03),
            inset 0 1px 0 rgba(255, 255, 255, 0.7),
            0 0 0 2px rgba(0, 102, 0, 0.08) !important;
  }
  100% {
    box-shadow:
            0 4px 12px rgba(0, 0, 0, 0.03),
            0 2px 3px rgba(0, 0, 0, 0.02),
            inset 0 1px 0 rgba(255, 255, 255, 0.7) !important;
  }
}

.app-search .form-control {
  animation: searchPulse 2s ease-in-out 1s forwards !important;
}

/* ======= DASHBOARD MAIN STYLING ======= */

/* Main Dashboard Container */
.page-content {
  background: linear-gradient(135deg, #fcfcfc 0%, #f9fff9 100%) !important;
  min-height: 100vh !important;
  padding: 1.75rem !important;
}

/* Container Fluid Improvements */
.container-fluid {
  padding: 0 1rem !important;
}

/* Welcome Section */
.welcome-section {
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 2rem !important;
  position: relative !important;
  padding-left: 1.25rem !important;
  animation: fadeInLeft 0.5s ease-out forwards !important;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Vertical accent bar */
.welcome-section::before {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  top: 0.25rem !important;
  bottom: 0.25rem !important;
  width: 4px !important;
  background: linear-gradient(to bottom, #005500, #00aa00) !important;
  border-radius: 8px !important;
}

/* Welcome Message Styling */
.welcome-message {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.5rem !important;
  color: #111827 !important;
  margin-bottom: 0.5rem !important;
  letter-spacing: -0.02em !important;
  line-height: 1.3 !important;
}

/* Admission Year Label */
.admission-year-title {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  color: #4B5563 !important;
  font-weight: 600 !important;
  font-size: 1.125rem !important;
  margin-top: 0.875rem !important;
  margin-bottom: 0.75rem !important;
  letter-spacing: -0.01em !important;
  display: block !important;
}

/* Enhanced Select Container for Admission Year */
.admission-year-select {
  max-width: 400px !important;
  margin-bottom: 2rem !important;
}

/* ======= SELECT DROPDOWN STYLING ======= */

/* Select Dropdown Styling */
.select2-selection {
  min-height: 42px !important;
}

.select2-selection__control {
  border: 1px solid rgba(229, 231, 235, 0.9) !important;
  border-radius: 10px !important;
  box-shadow:
          0 2px 8px rgba(0, 0, 0, 0.03),
          0 1px 2px rgba(0, 0, 0, 0.02),
          inset 0 1px 0 rgba(255, 255, 255, 0.5) !important;
  transition: all 0.25s cubic-bezier(0.16, 1, 0.3, 1) !important;
  min-height: 42px !important;
}

.select2-selection__control:hover {
  border-color: rgba(0, 85, 0, 0.3) !important;
}

.select2-selection__control--is-focused {
  border-color: rgba(0, 102, 0, 0.5) !important;
  box-shadow:
          0 0 0 3px rgba(0, 102, 0, 0.18),
          0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

.select2-selection__placeholder {
  color: #6B7280 !important;
}

.select2-selection__menu {
  border-radius: 10px !important;
  box-shadow:
          0 10px 25px -5px rgba(0, 0, 0, 0.1),
          0 5px 15px -5px rgba(0, 0, 0, 0.05) !important;
  overflow: hidden !important;
  border: 1px solid rgba(229, 231, 235, 0.8) !important;
}

.select2-selection__option {
  padding: 0.75rem 1rem !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
}

.select2-selection__option--is-focused {
  background-color: rgba(0, 85, 0, 0.1) !important;
}

.select2-selection__option--is-selected {
  background-color: rgba(0, 85, 0, 0.8) !important;
  color: white !important;
}

/* ======= CHART SECTION CARD STYLING ======= */

/* Card Base Styling */
.mini-stats-wid {
  border: none !important;
  border-radius: 12px !important;
  box-shadow:
          0 4px 25px -5px rgba(0, 0, 0, 0.05),
          0 1px 5px rgba(0, 0, 0, 0.03) !important;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1) !important;
  overflow: hidden !important;
  background: #ffffff !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  position: relative !important;
  margin-bottom: 0 !important;
}

.mini-stats-wid:hover {
  transform: translateY(-3px) !important;
  box-shadow:
          0 8px 30px -5px rgba(0, 0, 0, 0.08),
          0 1px 5px rgba(0, 0, 0, 0.03) !important;
}

/* Card Bottom Accent */
.mini-stats-wid::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 3px !important;
  background: linear-gradient(90deg,
          rgba(0, 85, 0, 0.8) 0%,
          rgba(0, 170, 0, 0.4) 100%) !important;
  opacity: 0 !important;
  transition: opacity 0.3s ease !important;
}

.mini-stats-wid:hover::after {
  opacity: 1 !important;
}

/* Card Header Section */
.mini-stats-wid .card-body {
  padding: 1.5rem !important;
  flex: 1 !important;
}

/* Title Styling */
.mini-stats-wid p.text-muted.fw-medium {
  color: #111827 !important;
  font-weight: 600 !important;
  font-size: 0.95rem !important;
  margin-bottom: 0.5rem !important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  letter-spacing: -0.01em !important;
  position: relative !important;
  display: inline-flex !important;
  align-items: center !important;
  opacity: 0.85 !important;
}

.mini-stats-wid p.text-muted.fw-medium::before {
  content: "" !important;
  display: inline-block !important;
  width: 8px !important;
  height: 8px !important;
  background: linear-gradient(135deg, #005500, #00aa00) !important;
  border-radius: 50% !important;
  margin-right: 0.5rem !important;
}

/* Stats Value Styling */
.mini-stats-wid h4.mb-0 {
  font-size: 1.75rem !important;
  font-weight: 700 !important;
  color: #111827 !important;
  margin-bottom: 0.25rem !important;
  letter-spacing: -0.02em !important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  transition: color 0.2s ease !important;
}

.mini-stats-wid:hover h4.mb-0 {
  color: #005500 !important;
}

/* Chart Container */
.mini-stats-wid .flex-shrink-0.align-self-center {
  margin-left: 0.75rem !important;
  transition: transform 0.3s ease !important;
}

.mini-stats-wid:hover .flex-shrink-0.align-self-center {
  transform: scale(1.05) !important;
}

/* Gender Stats Styling */
.mini-stats-wid .row {
  margin-top: 1rem !important;
}

.mini-stats-wid .col-md-6 {
  font-size: 0.875rem !important;
  color: #4B5563 !important;
  display: flex !important;
  align-items: center !important;
  padding: 0.25rem 0.5rem !important;
}

.mini-stats-wid .col-md-6 b {
  font-weight: 600 !important;
  color: #111827 !important;
  margin-left: 0.25rem !important;
}

/* Male Stats */
.mini-stats-wid .col-md-6:first-child::before {
  content: "" !important;
  display: inline-block !important;
  width: 8px !important;
  height: 8px !important;
  background-color: #3182CE !important;
  border-radius: 50% !important;
  margin-right: 0.35rem !important;
}

/* Female Stats */
.mini-stats-wid .col-md-6:last-child::before {
  content: "" !important;
  display: inline-block !important;
  width: 8px !important;
  height: 8px !important;
  background-color: #E53E3E !important;
  border-radius: 50% !important;
  margin-right: 0.35rem !important;
}

/* Footer Trend Section */
.mini-stats-wid .card-body.border-top {
  padding: 0.875rem 1.5rem !important;
  background: rgba(249, 250, 251, 0.5) !important;
  border-top: 1px solid rgba(229, 231, 235, 0.7) !important;
}

.mini-stats-wid .card-body.border-top p.mb-0 {
  font-size: 0.875rem !important;
  color: #4B5563 !important;
  font-weight: 500 !important;
}

/* ======= BADGE STYLING ======= */

/* Badge Styling */
.badge-soft-success {
  background: linear-gradient(135deg, rgba(0, 85, 0, 0.15), rgba(0, 170, 0, 0.15)) !important;
  color: #005500 !important;
  font-weight: 600 !important;
  padding: 0.35em 0.65em !important;
  border-radius: 6px !important;
  border: 1px solid rgba(0, 85, 0, 0.15) !important;
}

.badge-soft-danger {
  background: linear-gradient(135deg, rgba(220, 38, 38, 0.15), rgba(248, 113, 113, 0.15)) !important;
  color: #DC2626 !important;
  font-weight: 600 !important;
  padding: 0.35em 0.65em !important;
  border-radius: 6px !important;
  border: 1px solid rgba(220, 38, 38, 0.15) !important;
}

/* Icon Styling in Badge */
.bx.bx-trending-up,
.bx.bx-trending-down {
  font-size: 1rem !important;
  line-height: 0 !important;
  vertical-align: middle !important;
}

/* ======= LOADING STATE ======= */

/* Loading State */
.loading-container,
.mt-3:contains("Loading...") {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 200px !important;
  width: 100% !important;
  color: #6B7280 !important;
  font-weight: 500 !important;
  margin-top: 1rem !important;
}

.loading-container::before,
.mt-3:contains("Loading...")::before {
  content: "" !important;
  display: inline-block !important;
  width: 24px !important;
  height: 24px !important;
  border: 2px solid rgba(0, 85, 0, 0.3) !important;
  border-top-color: #005500 !important;
  border-radius: 50% !important;
  margin-right: 0.75rem !important;
  animation: spinner 0.8s linear infinite !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg) !important;
  }
}

/* ======= GRID LAYOUT AND SPACING ======= */

/* Add margin between cards in grid */
.col-lg-3 {
  padding: 0.75rem !important;
}

/* Ensure row has negative margin to compensate for card padding */
.row {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
  margin-bottom: 1.5rem !important;
}

/* Row Last Child No Margin */
.row:last-child {
  margin-bottom: 0 !important;
}

/* Ensure proper spacing between dashboard sections */
.chart-section + .row,
.row + .chart-section {
  margin-top: 2rem !important;
}

/* ======= VALIDATION STYLING ======= */

/* Form Feedback for Validation */
.invalid-feedback {
  color: #DC2626 !important;
  font-size: 0.875rem !important;
  margin-top: 0.25rem !important;
  font-weight: 500 !important;
}

/* ======= RESPONSIVE DESIGN ======= */

@media (max-width: 991.98px) {
  .col-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .app-search {
    margin-right: 1rem !important;
  }

  .app-search .form-control {
    height: 38px !important;
    font-size: 0.875rem !important;
    border-radius: 10px !important;
  }

  .app-search .position-relative::before {
    font-size: 0.8125rem !important;
    left: 1rem !important;
  }

  /* Remove bottom margin from last row of cards on mobile */
  .col-lg-3:last-child {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 767.98px) {
  .page-content {
    padding: 1rem !important;
  }

  .container-fluid {
    padding: 0 0.5rem !important;
  }

  .welcome-section {
    padding-left: 1rem !important;
  }

  .welcome-section::before {
    width: 3px !important;
  }

  .welcome-message {
    font-size: 1.25rem !important;
  }

  .admission-year-title {
    font-size: 1rem !important;
    margin-top: 0.5rem !important;
  }

  .mini-stats-wid .card-body {
    padding: 1.25rem !important;
  }

  .mini-stats-wid h4.mb-0 {
    font-size: 1.5rem !important;
  }

  .mini-stats-wid p.text-muted.fw-medium {
    font-size: 0.875rem !important;
  }

  .mini-stats-wid .card-body.border-top {
    padding: 0.75rem 1.25rem !important;
  }
}


/* Services Section Styling */

/* Card Wrapper Container */
.card-wrapper {
  padding: 1.5rem 0.5rem !important;
  position: relative !important;
  margin-bottom: 2rem !important;
}

/* Services Section Header */
.card-wrapper h2 {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.5rem !important;
  color: #111827 !important;
  margin-bottom: 1.5rem !important;
  letter-spacing: -0.02em !important;
  position: relative !important;
  display: inline-block !important;
  padding-left: 1.25rem !important;
  line-height: 1.4 !important;
}

/* Vertical accent for section header */
.card-wrapper h2::before {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 4px !important;
  height: 24px !important;
  background: linear-gradient(to bottom, #005500, #00aa00) !important;
  border-radius: 8px !important;
}

/* Card Container Grid */
.card-container {
  display: flex !important;
  flex-wrap: wrap !important;
  margin: 0 -0.75rem !important;
}

/* Individual Card Styling */
.card-container > div {
  padding: 0.75rem !important;
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1) !important;
}

.card-container > div:hover {
  transform: translateY(-5px) !important;
}

/* Card Component Styles */
.service-card {
  border: none !important;
  border-radius: 12px !important;
  box-shadow:
          0 4px 25px -5px rgba(0, 0, 0, 0.05),
          0 1px 5px rgba(0, 0, 0, 0.03) !important;
  overflow: hidden !important;
  background: #ffffff !important;
  height: 100% !important;
  position: relative !important;
  transition: box-shadow 0.3s ease !important;
}

.service-card:hover {
  box-shadow:
          0 8px 30px -5px rgba(0, 0, 0, 0.08),
          0 1px 5px rgba(0, 0, 0, 0.03) !important;
}

/* Card Image Container */
.service-card-image {
  position: relative !important;
  overflow: hidden !important;
  height: 160px !important;
}

.service-card-image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  transition: transform 0.5s ease !important;
}

.service-card:hover .service-card-image img {
  transform: scale(1.05) !important;
}

/* Card Content */
.service-card-content {
  padding: 1.25rem !important;
}

.service-card-title {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.125rem !important;
  color: #111827 !important;
  margin-bottom: 0.5rem !important;
  transition: color 0.2s ease !important;
}

.service-card:hover .service-card-title {
  color: #005500 !important;
}

/* Card Bottom Accent */
.service-card::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 3px !important;
  background: linear-gradient(90deg,
          rgba(0, 85, 0, 0.8) 0%,
          rgba(0, 170, 0, 0.4) 100%) !important;
  opacity: 0 !important;
  transition: opacity 0.3s ease !important;
}

.service-card:hover::after {
  opacity: 1 !important;
}


/* Responsive Adjustments */
@media (max-width: 767.98px) {
  .card-wrapper {
    padding: 1rem 0.5rem !important;
  }

  .card-wrapper h2 {
    font-size: 1.25rem !important;
    padding-left: 1rem !important;
    margin-bottom: 1.25rem !important;
  }

  .card-wrapper h2::before {
    height: 20px !important;
    width: 3px !important;
  }

  .service-card-image {
    height: 140px !important;
  }

  .service-card-content {
    padding: 1rem !important;
  }

  .service-card-title {
    font-size: 1rem !important;
  }
}

/* Loading State Styling */
.card-wrapper .placeholder-loader {
  margin-bottom: 1rem !important;
}
