/* Enhanced Login Page Styling */

/* Main container background with subtle gradient */
.login-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f8f9fa 0%, #f2f9f2 100%) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
}

/* Card styling with professional shadow */
.login-card {
  border: none !important;
  border-radius: 16px !important;
  overflow: hidden !important;
  box-shadow:
          0 20px 60px -10px rgba(0, 0, 0, 0.1),
          0 8px 20px -6px rgba(0, 0, 0, 0.05),
          0 0 0 1px rgba(0, 0, 0, 0.02) !important;
  max-width: 1000px;
  margin: 0 auto;
}

/* Carousel container */
.login-carousel {
  height: 540px !important;
  border-radius: 16px 0 0 16px !important;
  overflow: hidden !important;
}

/* Carousel images */
.carousel-image {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
  object-position: center !important;
  transition: transform 1.5s ease !important;
}

/* Form section styling */
.login-form-section {
  padding: 2.5rem !important;
  height: 540px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  background: white !important;
}

/* Logo and title section */
.login-header {
  text-align: center !important;
  margin-bottom: 2rem !important;
}

.login-logo {
  height: 85px !important;
  margin-bottom: 1.25rem !important;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.05)) !important;
  transition: transform 0.3s ease !important;
}

.login-logo:hover {
  transform: scale(1.05) !important;
}

.login-title {
  color: #005500 !important;
  font-size: 2.25rem !important;
  font-weight: 700 !important;
  margin: 0.5rem 0 !important;
  letter-spacing: -0.02em !important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
}

.login-subtitle {
  color: #6B7280 !important;
  font-size: 1rem !important;
  margin-top: 0.5rem !important;
  font-weight: 500 !important;
}

/* Form controls styling */
.login-form-label {
  font-weight: 600 !important;
  color: #374151 !important;
  margin-bottom: 0.5rem !important;
  font-size: 0.9375rem !important;
}

.login-input {
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid rgba(229, 231, 235, 0.9) !important;
  padding-left: 1rem !important;
  font-size: 1rem !important;
  transition: all 0.3s ease !important;
  box-shadow:
          0 2px 4px rgba(0, 0, 0, 0.02),
          inset 0 1px 0 rgba(255, 255, 255, 0.5) !important;
}

.login-input:focus {
  border-color: rgba(0, 85, 0, 0.4) !important;
  box-shadow:
          0 0 0 4px rgba(0, 85, 0, 0.15),
          0 1px 2px rgba(0, 0, 0, 0.05) !important;
}

.password-toggle-btn {
  border: 1px solid rgba(229, 231, 235, 0.9) !important;
  border-left: none !important;
  background-color: #f9fafb !important;
  color: #6B7280 !important;
  height: 50px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  transition: all 0.3s ease !important;
}

.password-toggle-btn:hover {
  background-color: #f3f4f6 !important;
  color: #374151 !important;
}

/* Forgot password link */
.forgot-password-link {
  color: #005500 !important;
  text-decoration: none !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
}

.forgot-password-link:hover {
  color: #007700 !important;
  text-decoration: underline !important;
}

/* Login button styling */
.login-btn {
  height: 50px !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  background: linear-gradient(150deg, #004400, #008000) !important;
  border: none !important;
  box-shadow:
          0 4px 12px rgba(0, 85, 0, 0.2),
          0 2px 4px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1) !important;
}

.login-btn:hover {
  transform: translateY(-2px) !important;
  box-shadow:
          0 6px 16px rgba(0, 85, 0, 0.25),
          0 3px 6px rgba(0, 0, 0, 0.15) !important;
  background: linear-gradient(150deg, #003700, #006800) !important;
}

.login-btn:active {
  transform: translateY(1px) !important;
  box-shadow:
          0 2px 8px rgba(0, 85, 0, 0.2),
          0 1px 2px rgba(0, 0, 0, 0.1) !important;
}

/* Apply Online button */
.apply-btn {
  border-radius: 10px !important;
  height: 50px !important;
  border: 2px solid #005500 !important;
  color: #005500 !important;
  font-weight: 600 !important;
  transition: all 0.3s ease !important;
  background: transparent !important;
}

.apply-btn:hover {
  background: rgba(0, 85, 0, 0.05) !important;
  border-color: #007700 !important;
  color: #007700 !important;
}

.apply-link {
  color: inherit !important;
  text-decoration: none !important;
  display: block !important;
  font-weight: 600 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Days remaining alert */
.days-remaining-alert {
  border-radius: 10px !important;
  padding: 0.75rem 1rem !important;
  margin-top: 1rem !important;
  background: linear-gradient(150deg, rgba(0, 85, 0, 0.05), rgba(0, 170, 0, 0.08)) !important;
  border: 1px solid rgba(0, 85, 0, 0.15) !important;
  color: #005500 !important;
  font-weight: 600 !important;
  text-align: center !important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.7) !important;
}

/* Modal styling */
.credentials-modal .modal-content {
  border-radius: 16px !important;
  border: none !important;
  box-shadow:
          0 20px 60px -10px rgba(0, 0, 0, 0.2),
          0 8px 20px -6px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
}

.credentials-modal .modal-header {
  background: linear-gradient(150deg, #004400, #008000) !important;
  color: white !important;
  border-bottom: none !important;
  padding: 1.25rem 1.5rem !important;
}

.credentials-modal .modal-title {
  font-weight: 700 !important;
  font-size: 1.25rem !important;
}

.credentials-modal .btn-close {
  color: white !important;
  filter: invert(1) brightness(200%) !important;
  opacity: 0.8 !important;
}

.credentials-modal .modal-body {
  padding: 1.5rem !important;
}

.credentials-modal .modal-footer {
  border-top: 1px solid rgba(229, 231, 235, 0.8) !important;
  padding: 1.25rem 1.5rem !important;
}

.credentials-modal .modal-footer button {
  border-radius: 10px !important;
  padding: 0.5rem 1.5rem !important;
  font-weight: 600 !important;
}

/* Responsive styles */
@media (max-width: 991.98px) {
  .login-carousel {
    height: 400px !important;
    border-radius: 16px 16px 0 0 !important;
  }

  .login-form-section {
    height: auto !important;
    padding: 2rem 1.5rem !important;
  }

  .login-card {
    max-width: 600px !important;
  }

  .login-title {
    font-size: 2rem !important;
  }
}

@media (max-width: 767.98px) {
  .login-carousel {
    height: 300px !important;
  }

  .login-logo {
    height: 70px !important;
    margin-bottom: 1rem !important;
  }

  .login-title {
    font-size: 1.75rem !important;
  }
}
