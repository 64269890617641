/* Enhanced styling for your existing table classes */
.table.table-striped.table-bordered.responsiveTable {
  width: 100% !important;
  margin-bottom: 1.5rem !important;
  color: #212529 !important;
  border-collapse: collapse !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
}

.dropdown-higher-z{
  z-index: 1050 !important;
}

/* Header styling */
.table.table-striped.table-bordered.responsiveTable thead {
  background-color: #f8f9fa !important;
  border-bottom: 2px solid #dee2e6 !important;
}

.table.table-striped.table-bordered.responsiveTable th {
  padding: 1rem !important;
  font-weight: 600 !important;
  text-align: left !important;
  vertical-align: middle !important;
  color: #495057 !important;
  text-transform: uppercase !important;
  font-size: 0.75rem !important;
  letter-spacing: 0.5px !important;
  white-space: nowrap !important;
}

/* Body styling */
.table.table-striped.table-bordered.responsiveTable tbody tr {
  border-bottom: 1px solid #f0f0f0 !important;
  transition: background-color 0.2s ease !important;
}

.table.table-striped.table-bordered.responsiveTable tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.table.table-striped.table-bordered.responsiveTable td {
  padding: 0.75rem 1rem !important;
  vertical-align: middle !important;
  line-height: 1.5 !important;
  color: #495057 !important;
}

/* Striped rows styling */
.table.table-striped.table-bordered.responsiveTable tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

/* Dropdown styling */
.table.table-striped.table-bordered.responsiveTable .dropdown-toggle {
  border: none !important;
  background: transparent !important;
  padding: 0.375rem !important;
  border-radius: 4px !important;
  transition: background-color 0.2s ease !important;
}

.table.table-striped.table-bordered.responsiveTable .dropdown-toggle:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.table.table-striped.table-bordered.responsiveTable .dropdown-menu {
  min-width: 150px !important;
  padding: 0.5rem 0 !important;
  margin: 0.125rem 0 0 !important;
  font-size: 0.875rem !important;
  border: 0 !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.table.table-striped.table-bordered.responsiveTable .dropdown-item {
  padding: 0.5rem 1.25rem !important;
  transition: background-color 0.2s ease !important;
}

.table.table-striped.table-bordered.responsiveTable .dropdown-item:hover {
  background-color: #f8f9fa !important;
}

/* Responsive styling for pivoted view */
@media (max-width: 767.98px) {
  .table.table-striped.table-bordered.responsiveTable td.pivoted {
    display: block !important;
    padding: 0.5rem 1rem !important;
    text-align: right !important;
    border-bottom: 1px solid #f0f0f0 !important;
  }

  .table.table-striped.table-bordered.responsiveTable td.pivoted:last-child {
    border-bottom: 0 !important;
  }

  .table.table-striped.table-bordered.responsiveTable td.pivoted .tdBefore {
    float: left !important;
    font-weight: 600 !important;
    color: #495057 !important;
    padding-right: 1rem !important;
  }

  .table.table-striped.table-bordered.responsiveTable tr {
    padding: 0.75rem 0 !important;
    margin-bottom: 1rem !important;
    display: block !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 6px !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) !important;
  }

  .table.table-striped.table-bordered.responsiveTable tbody {
    display: block !important;
  }

  .table.table-striped.table-bordered.responsiveTable thead {
    display: none !important;
  }
}
