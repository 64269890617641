/* Professional Search Form Styling - CSS Only */
.app-search.d-none.d-lg-block {
  position: relative !important;
  margin-right: 1.5rem !important;
}

.app-search .position-relative {
  position: relative !important;
}

.app-search .position-relative::before {
  content: "\f002" !important; /* Font Awesome search icon */
  font-family: "Font Awesome 5 Free", "FontAwesome" !important;
  font-weight: 900 !important;
  position: absolute !important;
  left: 1rem !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  font-size: 0.875rem !important;
  color: #005500 !important;
  opacity: 0.7 !important;
  z-index: 3 !important;
  pointer-events: none !important;
  transition: all 0.2s ease !important;
}

.app-search .form-control {
  height: 40px !important;
  padding-left: 2.75rem !important;
  padding-right: 1rem !important;
  background: rgba(255, 255, 255, 0.9) !important;
  border: 1px solid rgba(229, 231, 235, 0.8) !important;
  box-shadow:
          0 2px 8px rgba(0, 0, 0, 0.03),
          0 1px 2px rgba(0, 0, 0, 0.02),
          inset 0 1px 0 rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px !important;
  transition: all 0.25s cubic-bezier(0.16, 1, 0.3, 1) !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: -0.01em !important;
  color: #111827 !important;
}

.app-search .form-control::placeholder {
  color: #6B7280 !important;
  opacity: 0.75 !important;
}

.app-search .form-control:focus {
  background: #ffffff !important;
  border-color: rgba(0, 85, 0, 0.4) !important;
  box-shadow:
          0 0 0 4px rgba(0, 85, 0, 0.15),
          0 1px 2px rgba(0, 0, 0, 0.05) !important;
  outline: none !important;
}

.app-search .form-control:focus + .position-relative::before {
  opacity: 1 !important;
  color: #00aa00 !important;
}


/* Responsive design */
@media (max-width: 991.98px) {
  .app-search {
    margin-right: 1rem !important;
  }

  .app-search .form-control {
    height: 36px !important;
  }
}
