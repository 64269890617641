/* Chatbot Container */
#chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* Chatbot Icon */
#chatbot-icon {
  width: 50px;
  height: 50px;
  background-color: #075e54; /* WhatsApp Green */
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

#chatbot-icon:hover {
  transform: scale(1.1);
}

/* Chatbot Window */
#chatbot-window {
  width: 350px;
  height: 500px;
  background-color: #e5ddd5; /* WhatsApp Chat Background */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

/* Chatbot Header */
#chatbot-header {
  background-color: #075e54;
  color: white;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

#chatbot-header button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

#chatbot-header button:hover {
  transform: scale(1.2);
}

/* Chatbot Body */
#chatbot-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  scrollbar-color: #128c7e #e5ddd5;
}

/* Custom Scrollbar */
#chatbot-body::-webkit-scrollbar {
  width: 5px;
}

#chatbot-body::-webkit-scrollbar-thumb {
  background-color: #128c7e;
  border-radius: 10px;
}

#chatbot-body::-webkit-scrollbar-track {
  background: #e5ddd5;
}

/* Chatbot Messages */
#messages {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 10px;
}

/* User & Admin Messages */
.message {
  max-width: 75%;
  padding: 10px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 1.4;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-message {
  align-self: flex-end;
  background-color: #dcf8c6; /* WhatsApp Light Green */
  color: #075e54;
  border-top-right-radius: 0;
}

.admin-message {
  align-self: flex-start;
  background-color: white;
  color: black;
  border-top-left-radius: 0;
}

/* Message Timestamp */
.message-timestamp {
  font-size: 12px;
  color: gray;
  text-align: right;
  margin-top: 5px;
  display: block;
}

/* Chatbot Input */
#chatbot-input {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-top: 1px solid #ddd;
}

#chatbot-input textarea {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 14px;
  outline: none;
  resize: none;
  height: 40px;
}

/* WhatsApp-style Send Button */
#chatbot-input button {
  background-color: #128c7e; /* WhatsApp Green */
  color: white;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
  transition: background 0.2s ease-in-out, transform 0.1s;
}

#chatbot-input button:hover {
  background-color: #25d366; /* WhatsApp Bright Green */
  transform: scale(1.1);
}

/* Send icon */
#chatbot-input button svg {
  fill: white;
}

/* Mobile Responsiveness */
@media screen and (max-width: 400px) {
  #chatbot-window {
    width: 90%;
    height: 450px;
    right: 5%;
    bottom: 15%;
  }
}
