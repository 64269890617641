/* Ultra Premium Breadcrumb Styling */
.page-title-box {
  padding: 1rem 1.75rem !important;
  margin-bottom: 2.5rem !important;
  position: relative !important;
  background: linear-gradient(135deg, #ffffff 0%, #f9fff9 100%) !important;
  border-bottom: none !important;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.05) !important;
}

/* Modern accent element */
.page-title-box::before {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 1px !important;
  background: linear-gradient(90deg,
          rgba(0, 85, 0, 0.8) 0%,
          rgba(0, 85, 0, 0.4) 50%,
          rgba(244, 244, 245, 0) 100%) !important;
}

/* Title with premium typography */
.page-title-box h4.font-size-18 {
  color: #111827 !important;
  font-weight: 800 !important;
  margin-bottom: 0 !important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  letter-spacing: -0.02em !important;
  font-size: 1.5rem !important;
  position: relative !important;
  display: inline-flex !important;
  align-items: center !important;
  padding-left: 1rem !important;
}

/* Elegant side accent for title */
.page-title-box h4.font-size-18::before {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 4px !important;
  height: 24px !important;
  background: linear-gradient(to bottom, #005500, #00aa00) !important;
  border-radius: 8px !important;
}

/* Premium breadcrumb container */
.page-title-right {
  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(10px) saturate(180%) !important;
  -webkit-backdrop-filter: blur(10px) saturate(180%) !important;
  border-radius: 12px !important;
  padding: 0.625rem 1.25rem !important;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1) !important;
  border: 1px solid rgba(229, 231, 235, 0.8) !important;
  box-shadow:
          0 2px 10px rgba(0, 0, 0, 0.03),
          0 0 1px rgba(0, 0, 0, 0.1),
          inset 0 1px 0 rgba(255, 255, 255, 0.25) !important;
}

/* Refined breadcrumb base */
.page-title-right .breadcrumb {
  padding: 0 !important;
  margin: 0 !important;
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.25rem !important;
}

/* Polished breadcrumb items */
.page-title-right .breadcrumb-item {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  letter-spacing: -0.01em !important;
  display: flex !important;
  align-items: center !important;
}

/* Premium interactive links */
.page-title-right .breadcrumb-item a {
  color: #005500 !important;
  text-decoration: none !important;
  transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1) !important;
  border-radius: 8px !important;
  position: relative !important;
  z-index: 1 !important;
  background: transparent !important;
  white-space: nowrap !important;
}


.page-title-right .breadcrumb-item a:active {
  transform: translateY(0) !important;
  background-color: rgba(0, 85, 0, 0.12) !important;
}

/* Active item with premium styling */
//.page-title-right .breadcrumb-item.active {
//  color: #374151 !important;
//  font-weight: 600 !important;
//  padding: 0.375rem 0.75rem !important;
//  background: rgba(243, 244, 246, 0.8) !important;
//  border-radius: 8px !important;
//  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.04) !important;
//  white-space: nowrap !important;
//}

/* Elegant separator */
//.page-title-right .breadcrumb-item + .breadcrumb-item::before {
//  font-size: 1.25rem !important;
//  color: #9ca3af !important;
//  padding-right: 0.25rem !important;
//  padding-left: 0.25rem !important;
//  line-height: 0 !important;
//  opacity: 0.7 !important;
//}

/* Styled home icon */
.page-title-right .breadcrumb-item:first-child a {
  display: inline-flex !important;
  align-items: center !important;
  padding-left: 0.5rem !important;
  font-weight: 600 !important;
}

.page-title-right .breadcrumb-item:first-child a::before {
  content: "\f015" !important; /* Font Awesome home icon */
  font-family: "Font Awesome 5 Free", "FontAwesome" !important;
  font-weight: 900 !important;
  margin-right: 0.375rem !important;
  font-size: 0.875rem !important;
  background: linear-gradient(135deg, #005500, #00aa00) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
}

/* Responsive excellence */
@media (max-width: 991.98px) {
  .page-title-box {
    padding: 1.5rem 0 1.5rem !important;
    margin-bottom: 2rem !important;
  }

  .page-title-box h4.font-size-18 {
    font-size: 1.25rem !important;
  }
}

@media (max-width: 767.98px) {
  .page-title-box {
    padding: 1.25rem 0 1.25rem !important;
    margin-bottom: 1rem !important;
  }

  .page-title-box.d-sm-flex {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 1rem !important;
  }

  .page-title-box h4.font-size-18 {
    font-size: 1.125rem !important;
    padding-left: 0.75rem !important;
  }

  .page-title-box h4.font-size-18::before {
    height: 18px !important;
    width: 3px !important;
  }

  .page-title-right {
    width: 100% !important;
    overflow-x: auto !important;
    padding: 0.5rem 0.875rem !important;
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE/Edge */
  }

  .page-title-right::-webkit-scrollbar {
    display: none !important; /* Chrome/Safari/Opera */
  }

  .page-title-right .breadcrumb {
    flex-wrap: nowrap !important;
  }
}


/* Print optimization */
@media print {
  .page-title-box {
    background: none !important;
    border-bottom: 1px solid #000 !important;
    margin-bottom: 1rem !important;
    padding: 0.5rem 0 !important;
    box-shadow: none !important;
  }

  .page-title-box::before,
  .page-title-box h4.font-size-18::before {
    display: none !important;
  }

  .page-title-right {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
  }
}
